import React from 'react';

const ContentMadeToMeasure = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.8464 17.3669C28.8414 17.3721 27.8578 17.0797 27.02 16.527C26.1822 15.9743 25.528 15.1861 25.141 14.2625C24.754 13.3389 24.6514 12.3216 24.8461 11.3398C25.0409 10.358 25.5246 9.45586 26.2352 8.7482C26.9458 8.04055 27.8515 7.55922 28.8374 7.36526C29.8233 7.1713 30.8449 7.2735 31.7724 7.65889C32.6998 8.04428 33.4913 8.69541 34.0463 9.5297C34.6014 10.364 34.895 11.3439 34.8898 12.3446C34.8828 13.6745 34.3491 14.9478 33.4048 15.8881C32.4605 16.8285 31.1818 17.3599 29.8464 17.3669ZM29.8464 8.61752C29.1051 8.61229 28.379 8.82651 27.76 9.23285C27.1411 9.63918 26.657 10.2195 26.3697 10.9001C26.0823 11.5806 26.0047 12.3307 26.1461 13.0555C26.2875 13.7802 26.6418 14.4466 27.1642 14.9705C27.6865 15.4943 28.3534 15.8519 29.0801 15.9978C29.8068 16.1438 30.5604 16.0716 31.2458 15.7904C31.9313 15.5091 32.5173 15.0315 32.9298 14.4181C33.3422 13.8046 33.5626 13.0829 33.5626 12.3446C33.5626 11.3607 33.172 10.4167 32.4758 9.71846C31.7797 9.02026 30.8344 8.6245 29.8464 8.61752Z"
      fill="#FCFCFD"
    />
    <path
      d="M28.4145 47.9975H26.5832C26.4072 47.9975 26.2384 47.9278 26.114 47.8039C25.9895 47.6799 25.9196 47.5119 25.9196 47.3366V33.6708H23.5837C23.4165 33.6714 23.2554 33.6093 23.1324 33.4966C23.0093 33.3839 22.9335 33.2289 22.9201 33.0629L21.6726 20.2431C21.6613 20.1054 21.6936 19.9674 21.765 19.8489C21.8364 19.7303 21.9434 19.6372 22.0709 19.5823L24.5658 18.525C24.6434 18.4863 24.7283 18.4647 24.815 18.4614C24.9017 18.458 24.9883 18.4731 25.0687 18.5056C25.1491 18.5381 25.2215 18.5872 25.2813 18.6498C25.341 18.7124 25.3868 18.787 25.4154 18.8686C25.4505 18.9484 25.4695 19.0344 25.4711 19.1216C25.4728 19.2087 25.4569 19.2951 25.4248 19.3761C25.3927 19.4572 25.3451 19.5314 25.2842 19.5939C25.2233 19.6565 25.1507 19.7066 25.0703 19.7411L23.0264 20.6132L24.1676 32.3492H26.5832C26.7592 32.3492 26.9279 32.4189 27.0523 32.5428C27.1768 32.6667 27.2468 32.8348 27.2468 33.01V46.6758H28.3883C28.5643 46.6758 28.733 46.7455 28.8575 46.8694C28.9819 46.9933 29.0519 47.1614 29.0519 47.3366C29.052 47.5074 28.9856 47.6716 28.8669 47.7948C28.7481 47.918 28.5859 47.9906 28.4145 47.9975Z"
      fill="#FCFCFD"
    />
    <path
      d="M33.087 48.0008H31.2822C31.1062 48.0008 30.9375 47.9311 30.8131 47.8072C30.6886 47.6832 30.6187 47.5152 30.6187 47.3399C30.6187 47.1647 30.6886 46.9967 30.8131 46.8727C30.9375 46.7488 31.1062 46.6791 31.2822 46.6791H32.4234V33.0133C32.4234 32.8381 32.4934 32.67 32.6179 32.5461C32.7423 32.4222 32.911 32.3525 33.087 32.3525H35.5026L36.6175 20.6166L34.5736 19.7444C34.4201 19.665 34.3004 19.5327 34.237 19.3724C34.1736 19.212 34.1706 19.0343 34.2286 18.8719C34.2632 18.7919 34.3135 18.7196 34.3763 18.659C34.4392 18.5983 34.5133 18.5505 34.5947 18.5186C34.6761 18.4867 34.7632 18.4711 34.8507 18.4728C34.9382 18.4744 35.0242 18.4933 35.1044 18.5283L37.5997 19.5856C37.7271 19.6405 37.8341 19.7336 37.9055 19.8522C37.977 19.9707 38.0093 20.1087 37.9979 20.2465L36.7501 33.0662C36.7367 33.2322 36.6612 33.3872 36.5382 33.4999C36.4151 33.6126 36.2537 33.6747 36.0865 33.6741H33.7506V47.3399C33.7506 47.5152 33.681 47.6832 33.5565 47.8072C33.4321 47.9311 33.263 48.0008 33.087 48.0008Z"
      fill="#FCFCFD"
    />
    <path
      d="M14.8016 29.499C12.2458 29.4973 9.73381 28.8369 7.51017 27.5824C5.28652 26.3278 3.42683 24.5216 2.11168 22.3394C0.796522 20.1572 0.0706646 17.6733 0.00490356 15.1291C-0.0608575 12.5849 0.535784 10.0672 1.73646 7.82046C2.93714 5.57375 4.70106 3.67456 6.85694 2.30773C9.01283 0.940911 11.4872 0.152691 14.0395 0.0200441C16.5917 -0.112602 19.1349 0.41485 21.4219 1.55076C23.709 2.68668 25.662 4.39266 27.091 6.5027C27.14 6.57322 27.1742 6.65291 27.1914 6.73695C27.2086 6.82099 27.2085 6.90753 27.1911 6.99153C27.1737 7.07554 27.1395 7.15503 27.0903 7.22546C27.0412 7.2959 26.9783 7.35581 26.9053 7.40132C26.8361 7.45136 26.7576 7.48704 26.6743 7.50618C26.591 7.52532 26.5045 7.52743 26.4203 7.51263C26.3361 7.49783 26.2558 7.46642 26.1841 7.42003C26.1123 7.37364 26.0508 7.31317 26.0029 7.24256C24.5645 5.11193 22.5438 3.43574 20.1797 2.41163C17.8155 1.38753 15.2067 1.05829 12.6608 1.463C10.1149 1.86771 7.73814 2.98929 5.81166 4.69545C3.88517 6.40161 2.48912 8.62126 1.78831 11.092C1.0875 13.5626 1.11127 16.1814 1.85667 18.6391C2.60208 21.0968 4.038 23.2907 5.99505 24.962C7.9521 26.6333 10.3487 27.7122 12.9015 28.0712C15.4543 28.4301 18.057 28.0544 20.4023 26.988C20.4819 26.939 20.5712 26.9073 20.6641 26.8951C20.7569 26.8828 20.8513 26.8903 20.9411 26.917C21.0309 26.9437 21.1139 26.989 21.1848 27.05C21.2557 27.1109 21.3127 27.1861 21.3523 27.2707C21.3919 27.3552 21.4131 27.4471 21.4145 27.5404C21.4159 27.6337 21.3975 27.7261 21.3604 27.8118C21.3233 27.8974 21.2684 27.9743 21.1993 28.0373C21.1303 28.1003 21.0485 28.148 20.9596 28.1774C19.0295 29.0639 16.9269 29.5151 14.8016 29.499Z"
      fill="#3BFFAD"
    />
    <path
      d="M19.2019 19.7988C19.1121 19.7976 19.0236 19.7783 18.9414 19.742C18.8593 19.7056 18.7854 19.6531 18.7243 19.5874L14.318 15.226C14.2033 15.0932 14.1379 14.9254 14.1323 14.7504V7.03191C14.1323 6.85665 14.202 6.68862 14.3264 6.56469C14.4509 6.44077 14.6199 6.37109 14.7959 6.37109C14.9719 6.37109 15.1406 6.44077 15.2651 6.56469C15.3895 6.68862 15.4595 6.85665 15.4595 7.03191V14.4332L19.6533 18.6094C19.7168 18.6688 19.7674 18.7402 19.802 18.8198C19.8366 18.8994 19.8545 18.9854 19.8545 19.0721C19.8545 19.1588 19.8366 19.2446 19.802 19.3241C19.7674 19.4037 19.7168 19.4752 19.6533 19.5345C19.6 19.6067 19.5326 19.6675 19.455 19.7129C19.3774 19.7584 19.2912 19.7875 19.2019 19.7988Z"
      fill="#3BFFAD"
    />
  </svg>
);

export default ContentMadeToMeasure;
